import React from "react"
import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
// import ProductHero from "../components/ProductHero"
// import ProductValues from "../components/ProductValues"
// import ProductCategories from "../components/ProductCategories"
// import ProductHowItWorks from "../components/ProductHowItWorks"
// import ProductCTA from "../components/ProductCTA"
// import ProductSmokingHero from "../components/ProductSmokingHero"
import Boo from "../components/Camera";
import '../App.scss'
export default function Home() {
  return (
    <Layout>
      <Seo title="Home" />
      <Boo />
      {/* <ProductHero dynamic />
      <ProductValues />
      <ProductCategories />
      <ProductHowItWorks />
      <ProductCTA />
      <ProductSmokingHero /> */}
    </Layout>
  )
}