import React from "react";
import { ColorExtractor } from "react-color-extractor";
import 'react-html5-camera-photo/build/css/index.css';
const IMAGE_STYLES = { 
  marginTop: 120,
  display: "flex",
  justifyContent: "center",
  margin: 'auto',
            objectFit: "none",
            width: "25px",
            height: "25px",
            zoom: 10
};
const SWATCHES_STYLES = {
  marginTop: 20,
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  color: "white"
};
export class Basic extends React.Component {
  state = { colors: [] };
  renderSwatches = () => {
    // console.log("go", this.props.prop)
    const { colors } = this.state;
    return colors.map((color, id) => {
      return (
        <div
          key={id}
          style={{
            backgroundColor: color,
            width: 150,
            height: 150
          }}
        >
          {color}
        </div>
      );
    });
  };
  getColors = colors =>
    this.setState(state => ({ colors: [...state.colors, ...colors] }));
  render() {
    return (
      <div>
        <ColorExtractor getColors={this.getColors}>
          <img alt="Preview" src={this.props.dataUri} style={IMAGE_STYLES} />
        </ColorExtractor>
        <div style={SWATCHES_STYLES}>{this.renderSwatches()}</div>
      </div>
    );
  }
}