import React, { useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
// import ImagePreview from './ImagePreview'; // source code : ./src/demo/AppWithImagePreview/ImagePreview
import { Basic } from './Basic';
function Boo (props) {
  const [dataUri, setDataUri] = useState('');
  function handleTakePhotoAnimationDone (dataUri) {
    console.log('takePhoto');
    setDataUri(dataUri);
  }
  const isFullscreen = false;
  return (
    <div>
      {
        (dataUri)
          ? 
          <div>
            {/* <ImagePreview dataUri={dataUri}
            isFullscreen={isFullscreen}
          /> */}
          <Basic dataUri={dataUri}/>
          </div>
          : 
          <div>
<Camera 
            onTakePhotoAnimationDone = {handleTakePhotoAnimationDone}
            isFullscreen={isFullscreen}
            idealFacingMode = {FACING_MODES.ENVIRONMENT}
            // idealResolution = {{width: 100, height: 480}}
          />
            </div>
      }
    </div>
  );
}
export default Boo;